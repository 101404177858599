import { preferredThemeModeLocalStorageKey } from '/constants';
import { ThemeMode } from '/common/models';
import { setThemeModeStylingMetadata } from '/helpers';

function initThemeModeSystemPreferences() {
  handleThemeModeSystemPreferencesInitialization(
    doesSystemHaveDarkThemeSetAsDefault() ? ThemeMode.Dark : ThemeMode.Light,
    preferredThemeModeLocalStorageKey
  );
}

function handleThemeModeSystemPreferencesInitialization(
  themeModePreferredBySystem: ThemeMode,
  localStorageKey: string
) {
  const preferredThemeMode = localStorage.getItem(preferredThemeModeLocalStorageKey) as ThemeMode | null;
  let targetThemeMode: ThemeMode | null = null;

  if (!preferredThemeMode) {
    localStorage.setItem(localStorageKey, themeModePreferredBySystem);
  } else {
    targetThemeMode = preferredThemeMode;
  }

  setThemeModeStylingMetadata(targetThemeMode || themeModePreferredBySystem);
}

function doesSystemHaveDarkThemeSetAsDefault(): boolean {
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

initThemeModeSystemPreferences();
